<template>
  <div class="order-add">
    <div class="usable-count-container" v-if="usableCount >= 0">
      可用激活码数量: <span>{{ usableCount }}</span>
    </div>
    <div class="add-container add-single-order">
      <h3>添加单个订单</h3>
      <div>
        <span>订单号：</span
        ><i-input
          v-model="order"
          placeholder="输入订单号"
          style="width: 300px"
        ></i-input>
      </div>
      <Button
        style="width: 300px; margin-top: 32px; margin-left: 20px"
        type="primary"
        @click="addSingleOrder"
        >添加</Button
      >
    </div>
    <div class="add-container add-multi-orders">
      <h3>批量添加</h3>
      <div>
        <Upload
          :action="ordersUploadUrl"
          :format="['txt']"
          :before-upload="handleUpload"
          :on-format-error="handleFormatError"
          :on-exceeded-size="handleMaxSize"
          :max-size="10240"
        >
          <Button long icon="ios-cloud-upload-outline">上传订单文件</Button>
        </Upload>

        <div>
          <span>上传文件必须为txt格式,每行一个订单号,文件大小不可超过10M</span>
        </div>
        <div class="upload-result" v-if="showUploadResult">
          <div>上传失败的订单号:</div>
          <div v-if="exist_list">
            <span>已存在的订单:</span>{{ exist_list.toString() }}
          </div>
          <div v-if="remain_list">
            <span>剩余的订单:</span>{{ remain_list.toString() }}
          </div>
          <div v-if="error_list">
            <span>格式不符合要求的订单:</span>{{ error_list.toString() }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PATH_ORDER_BULK_ADD } from "@/api";
import { BASE_URL } from "@/util/service";
import api from "@/api";
import Store from '@/store';

export default {
  data() {
    return {
      usableCount: -1,
      order: null,
      ordersUploadUrl: BASE_URL + PATH_ORDER_BULK_ADD,
      uploadHeaders:{
        'withCredentials':true
      },
      remain_list: null,
      exist_list: null,
      error_list: null,
      showUploadResult: false,
      file:null
    };
  },
  methods: {
    addSingleOrder() {
      if (!this.order || this.orderr === "") {
        this.$Message.info("请先输入订单号");
        return;
      }
      api
        .orderAdd(this.order)
        .then((res) => {
          this.$log.debug(res);
          this.$Message.success("添加成功");
          this.order = null;
          this.loadData();
        })
        .catch((err) => {
          this.$Message.error("添加失败:" + err.message);
        });
    },
    handleUpload(file) {
      this.file = file;
      this.showUploadResult = false;
      this.upload(file);
      return false;
    },
    upload(file){
      api.uploadOrderFile(file).then(res=>{
        this.handleSuccess(res.data);
      }).catch(err=>{
        this.$log.error(err);
        this.handleFormatError(file);
      })
    },
    handleSuccess(res) {
      console.log("handleSuccess: ",res);
      if (res.code != "OK") {
        this.$Message.error(res.message);
        if (res.code === "account.not.logined") { // 
          //进行用户登录状态清除
          Store.clearUserLogin();
          this.$router.push({ name: "login" , query: {
            redirect: this.$route.fullPath
        }});
        }
        return;
      }
      this.remain_list = this.error_list = this.error_list = null;
      if(res.result.remain_list && res.result.remain_list.length > 0) {
        this.remain_list = res.result.remain_list;
      }
      if (res.result.exist_list && res.result.exist_list.length > 0) {
        this.exist_list = res.result.exist_list;
      }
      if (res.result.error_list && res.result.error_list.length > 0) {
        this.error_list = res.result.error_list;
      }
      if (this.error_list || this.exist_list || this.remain_list) {
        this.showUploadResult = true;
      } else {
        this.showUploadResult = false;
        this.$Message.success("上传成功");
        this.loadData();
      }
    },
    handleFormatError(file) {
      // console.log("handleFormatError: ",file);
      if(file && file.name.indexOf('.txt') >= 0){
        return ;
      }
      this.$Notice.error({
        title: "The file format is incorrect",
        desc: file.name + " 的文件格式不对.必须为txt格式",
      });
    },
    handleMaxSize(file) {
      this.$Notice.error({
        title: "文件大小限制",
        desc: "文件:  " + file.name + " 太大,超过10M.",
      });
    },
    loadData() {
      api
        .merchantOrderRemainNum()
        .then((res) => {
          if(res.order_num){
            this.usableCount = res.order_num;
          }
        })
        .catch((err) => {
          this.$Message.error(err.message);
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style lang="less" scoped>
.order-add {
  width: 100%;
  padding: 12px;

  .usable-count-container {
    text-align: center;
    font-size: 18px;
    padding: 12px;
    border: 1px dashed #2d8cf0;

    span {
      color: red;
    }
  }

  .add-container {
    margin-top: 28px;

    h3 {
      margin-bottom: 20px;
      border-bottom: 1px solid #2d8cf0;
      padding-bottom: 12px;
    }
  }

  .upload-result {
    width: 100%;
    margin-top: 20px;
    padding: 12px;
    border-top: 1px solid #2d8cf0;
    font-size: 15px;
    color: red;
    word-break:break-all; 
  }
}
</style>

